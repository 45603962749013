// Vue and Plugins Imports
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueLazyload from "vue-lazyload";
import Vue2Editor from "vue2-editor";
import VueQRCodeComponent from "vue-qrcode-component";
import AOS from "aos";
import "aos/dist/aos.css";
import BusinessHours from 'vue-business-hours';
import { VBHover } from "bootstrap-vue";


// Use Plugins
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.use(VueSweetalert2);
Vue.use(VueLazyload);
Vue.use(Vue2Editor);
Vue.use(BusinessHours);
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(VBHover);

// AOS Configuration
AOS.init({
  disable: "mobile",
});

// Vue Instance
new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');

// Configurations
Vue.config.productionTip = false;

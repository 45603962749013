import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainDisplay",
    component: () =>
      import(/* webpackChunkName: "MainDisplay" */ "@/views/MainDisplay.vue"),
    meta: {
      title: "Integratie partners ",
    },
  },

  {
    path: "*",
    name: "/",
    component: () =>
      import(/* webpackChunkName: "MainDisplay" */ "@/views/MainDisplay.vue"),
    meta: { title: " Integratiepartners| Oeps pagina niet gevonden" },
  },
];

const router = new VueRouter({
  mode: "history",
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
